@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
*:not(b) {
  font-family: Montserrat, Arial, Helvetica, sans-serif !important;
  font-weight: 500 !important;
}

:root {
  --brand-blue: #F34265; /* these were initially blue and not pink */
  --brand-green: #14292F;
  --brand-dark-blue: #F34265;
  --brand-dark-green: #14292F;
  --brand-light-blue: #F34265;
  --brand-light-green: #14292F;
  --brand-lighter-blue: #F34265;
  --brand-lighter-green: #14292F;
  --brand-lightest-blue: #F34265;
  --brand-lightest-green: #14292F;
}

/* #react-admin-title {
  font-weight: bold;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.survey-response-container {
  display: flex;
}

.survey-response-container-left-half {
  margin-right: 2vw;
  border: 1px solid;
  padding: 30px;
}

/* .inline-form {
  display: inline-flex;
  margin-right: 1em;
} */

.first-col {
  width: 5vw;
}

.inline-form-full-width {
  display: inline-flex;
  margin-right: 1em;
}

.text-center {
  text-align: center;
}

.unauthorized {
  margin-top: 5em;
  color: dimgray;
}

.unauthorized-401 {
  font-size: 80pt;
}

.unauthorized-msg {
  font-size: 18pt;
}

.unauthorized-redirect {
  margin: 0.5em;
  font-size: 14pt;
}

.unauthorized-redirect-link {
  color: rgb(0, 0, 133);
  text-decoration: underline;
  cursor: pointer;
}

.left-toolbar {
  justify-content: flex-start !important;
}

.MuiSlider-thumb {
  color: grey;
}

.WithStyles\(ForwardRef\(Slider\)\)-root-65 {
  color: var(--brand-green) !important;
}

.question-slider-group {
  margin-bottom: 4vh;
}

.survey_scale {
  display: flex;
  justify-content: space-between;
}

.survey_scale p {
  margin: 0;
}

.show-box {
  min-height: 500px;
  background-color: white;
  padding: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.show-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-height: 650px;
}

.show-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
}

.survey-show-box {
  min-height: 890px;
}

.survey-show-right {
  text-align: left;
  max-width: 30vw;
}

.statistics-card {
  padding: 16px;
}

.form-group {
  display: flex;
  width: auto !important;
}

.numberInput {
  max-width: 80px;
  margin-right: 15px !important;
}

#root .import-button {
  padding: 4px 5px;
  margin-right: 10px;
  font-size: 0.8125rem;
}

.dropzone-area {
  border: 2px solid var(--brand-light-green);
  border-radius: 5px;
  text-align: center;
}

.dropzone-input {
  padding: 10vh 10vw;
  margin: 0;
}

.import-dialog-text-input {
  margin-top: 1em;
}

.import-dialog-text-inputbox.MuiTextField-root {
  margin-top: 5px;
  width: 5em;
}

.center-button.MuiButtonBase-root {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.bold {
  font-weight: bold !important;
}

.filename-area {
  border: 2px solid var(--brand-light-green);
  padding: 2vh 10vw;
  border-radius: 5px;
  text-align: center;
}

.text-only-page {
  padding: 10px;
}

#scheduled_date-helper-text, #upload-modal-helper-text {
  width: 200px;
  margin-left: 0;
}

#stats-dialog-input {
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
}

#stats-dialog-input-field {
  max-width: 50px;
}

#stats-dialog-input-label {
  margin-right: 10px;
  align-self: center;
}

#root .materialui-daterange-picker-makeStyles-dateRangePickerContainer-1 {
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 10;
}

header.MuiPaper-root button[aria-label="Refresh"], header.MuiPaper-root div[role="progressbar"] {
  display: none;
}

@media (min-width: 960px) {
  #left-menu-bar {
    margin-top: 1.5em;
  }
}
#help-survey-dialog .MuiDialogContent-root{
  overflow-y: initial;
}

.workcoach-email .Mui-error#email-helper-text{
  display:none;
}

.workcoach-email .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color:rgba(0, 0, 0, 0.23);
}

.survey-reminder .MuiToolbar-regular{
  justify-content: initial;
}